.center-block-login {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #008BA7;
}

.container-login a {
  text-decoration: none;
}

.login-title { 
  font-size: 40px;
  font-family: 'NTR', sans-serif;
  text-align: center;
}

.yellow, .login-form-forgot:hover {
  color: #EFFF0E;
}

.white {
  color: white;
}

.login-form-forgot {
  float: right;
  color: white;
}

.ant-row.ant-form-item {
  margin: 0px !important;
}