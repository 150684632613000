@import '~antd/dist/antd.css';

* {
  font-family: 'Fira Sans', sans-serif;
  margin: 0;
  padding: 0;
}

.text-center {
  text-align: center;
}

.ant-layout-header {
  background: #008BA7;
}

.ant-menu-dark {
  background: #008BA7;
  color: white;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #008BA7;
}

.ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #EFFF0E;
}

.ant-btn-primary:hover, .ant-btn-primary:focus, .ant-btn-primary {
  background-color: #008BA7;
  border-color: #008BA7
}

.content-auto {
  background: white;
  padding: 24px;
  min-height: calc(100vh - 64px - 24px);
  margin: 16px;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.ant-calendar-picker, .ant-input-number {
  width: 100%;
}
