.ant-carousel .slick-dots li.slick-active button {
  background: blue !important;
  opacity: 1 !important;
  width: 24px !important;
}

.ant-carousel .slick-dots li button {
  border: 0 !important;
  cursor: pointer !important;
  background: chartreuse !important;
  opacity: 1 !important;
  width: 16px !important;
  height: 3px !important;
  border-radius: 1px !important;
  outline: none !important;
  font-size: 0 !important;
  color: chartreuse !important;
  -webkit-transition: all .4s !important;
  -o-transition: all .4s !important;
  transition: all .4s !important;
  padding: 0 !important;
}